import React from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';

const FeesByOrderModal = ({ open, handleClose, header, results }) => {
  if (!header || !results || results.length === 0) {
    return null; // Si no hay datos, no se renderiza nada.
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        border: '1px solid #ccc',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}>
        {/* Encabezado Principal */}
        <Box
          sx={{
            mb: 3,
            textAlign: 'center', // Centra el contenido
            border: '1px solid',
            p: 2, // Padding para darle espacio
            borderRadius: 2, // Bordes redondeados para un mejor diseño
            borderColor: '#4a90e2'
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}
          >
            {header.constructora_info}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: '#555', fontStyle: 'italic', mb: 0.5 }}
          >
            Actualizado a la fecha: {header.updated_at_date}
          </Typography>
          <Typography variant="body2" sx={{ color: '#555' }}>
            {header.date_filter_text}
          </Typography>
        </Box>

        {/* Título del Reporte */}
        <Typography variant="h5" component="h2" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold', color: '#4a90e2' }}>
          {header.plan_name}
        </Typography>

        {/* Tabla de Resultados */}
        <TableContainer component={Paper} sx={{ border: '1px solid #ddd', borderRadius: 2 }}>
          <Table aria-label="fees by order report">
            <TableBody>
              {results.map((result, index) => (
                <React.Fragment key={index}>
                  {/* Cabecera de cada contrato */}
                  <TableRow sx={{ backgroundColor: '#f4f6f8' }}>
                    <TableCell colSpan={4}>
                      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        Órden: {result.contract_header.contract_number}
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#333' }}>
                        Suscriptor: {result.contract_header.subscriber_name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#555' }}>
                        Plan: {result.contract_header.plan_name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#555' }}>
                        Grupo: {result.contract_header.group_name}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Cuerpo con cuotas e información adicional en una sola fila */}
                  <TableRow>
                    {/* Columna izquierda - Cuotas */}
                    <TableCell>
                      <Typography variant="body2">
                        <strong>Cuotas emitidas:</strong> {result.total_cuotas_emitidas} <br />
                        <strong>Cuotas cobradas:</strong> {result.total_cuotas_cobradas} <br />
                        <strong>Cuotas adeudadas:</strong> {result.total_cuotas_adeudadas} <br />
                        {result.total_cuotas_adelanto > 0 && (
                          <><strong>Cuotas adelanto c/puntaje:</strong> {result.total_cuotas_adelanto}</>
                        )}
                      </Typography>
                    </TableCell>

                    {/* Columna derecha - Totales */}
                    <TableCell>
                      <Typography variant="body2">
                        <strong>Inicia en cuota:</strong> {result.cuota_inicio} <br />
                        <strong>Sumando un total de:</strong> ${result.total_suma_pagada.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br />
                        <strong>Sumando un total de (sin intereses):</strong> ${result.total_suma_sin_interes.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br />
                        {result.total_cuotas_adelanto > 0 && (
                          <>
                            <strong>Suma total de cupones de puntaje:</strong> ${result.total_suma_puntaje.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Separador entre contratos */}
                  <TableRow>
                    <TableCell colSpan={4} sx={{ borderBottom: '2px solid #ccc', mb: 3 }}></TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default FeesByOrderModal;
