import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addInvoices,
  addRegisterPayment,
  getContractFilter,
} from "../api/endpoints/registerPayment";
import {
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  RowTableCell,
  SelectFormControl,
} from "../assets/styles/tables";
import { resetAddInvoiceStatus } from "../features/registerPayments/registerPaymentSlice";

const RegisterPaymentHeader = ({ onRegistrarClick, table }) => {
  const dispatch = useDispatch();
  const [contratoSeleccionado, setContratoSeleccionado] = useState(null);
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);
  const getFormattedCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [selectedDate, setSelectedDate] = useState(getFormattedCurrentDate());
  const contratos = useSelector((state) => state.registerPayment.cupones);
  const entidadesRec = useSelector(
    (state) => state.legalEntity.recLegalEntities
  );
  const [makeElectronicInvoice, setMakeElectronicInvoice] = useState(false);

  const { salePoints } = useSelector((state) => state.salePoints);
  const [entidadSeleccionada, setEntidadSeleccionada] = useState(null);
  const [selectedSalePoint, setSelectedSalePoint] = useState(salePoints[0]);
  const afip = useSelector((state) => state.afip);
  const { branch, uuid } = useSelector((state) => state.userLogged);

  useEffect(() => {
    if (entidadesRec && entidadesRec.length > 0) {
      setEntidadSeleccionada(entidadesRec[0].uuid);
    }
  }, [entidadesRec]);

  useEffect(() => {
  }, [dispatch]);

  const [filtros, setFiltros] = useState({
    plan: plans[0].uuid,
    group: groups[0].uuid,
    contract_number: "0",
  });

  useEffect(() => {
    dispatch(getContractFilter(filtros));
  }, [filtros]);

  const handleFilterChange = (name, value) => {
    setFiltros({
      ...filtros,
      [name]: value,
    });
  };

  const handleContratoChange = (event, newValue) => {
    if (newValue) {
      // Calculamos en qué etapa de vencimiento está
      const currentDate = new Date(selectedDate);
      const firstDue = new Date(newValue.first_due_date);
      const secondDue = new Date(newValue.second_due_date);
      const thirdDue = new Date(newValue.third_due_date);

      let dueStage = "";
      let amount = 0;
      let date = "";

      if (currentDate <= firstDue) {
        dueStage = "first_due";
        amount = newValue.first_due_amount;
        date = newValue.first_due_date;
      } else if (currentDate <= secondDue) {
        dueStage = "second_due";
        amount = newValue.second_due_amount;
        date = newValue.second_due_date;
      } else if (currentDate <= thirdDue) {
        dueStage = "third_due";
        amount = newValue.third_due_amount;
        date = newValue.third_due_date;
      } else {
        dueStage = "generate_new_due";
      }

      // Agregamos la etapa de vencimiento al contrato
      newValue.dueStage = dueStage;
      newValue.amount = amount;
      newValue.date = date;
    }
    setContratoSeleccionado(newValue);
  };

  const handleRegistrarClick = () => {
    if (contratoSeleccionado) {
      if (contratoSeleccionado.dueStage === "generate_new_due") {
        alert("Debe generar un nuevo vencimiento");
      } else {
        onRegistrarClick(contratoSeleccionado);
      }
    }
  };

  const handleSaveCollection = async () => {
    // Mapea el array table para generar la estructura requerida
    const paymentsToRegister = table.map((item) => ({
      uuid: item.uuid,
      first_due_amount: item.amount,
      cancellation_date: item.date,
      uuid_legal_entity: entidadSeleccionada,
    }));
    try {
      const result = await dispatch(
        addRegisterPayment(paymentsToRegister)
      ).unwrap();
      // Check the status from result here
      if (result.status === 201) {
        const invoiceData = {
          make_electronic_invoice: makeElectronicInvoice,
          token:
            "PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pgo8c3NvIHZlcnNpb249IjIuMCI+CiAgICA8aWQgc3JjPSJDTj13c2FhaG9tbywgTz1BRklQLCBDPUFSLCBTRVJJQUxOVU1CRVI9Q1VJVCAzMzY5MzQ1MDIzOSIgZHN0PSJDTj13c2ZlLCBPPUFGSVAsIEM9QVIiIHVuaXF1ZV9pZD0iMTQxNzAyMDg0NiIgZ2VuX3RpbWU9IjE3MDAyMzg4NjYiIGV4cF90aW1lPSIxNzAwMjgyMTI2Ii8+CiAgICA8b3BlcmF0aW9uIHR5cGU9ImxvZ2luIiB2YWx1ZT0iZ3JhbnRlZCI+CiAgICAgICAgPGxvZ2luIGVudGl0eT0iMzM2OTM0NTAyMzkiIHNlcnZpY2U9IndzZmUiIHVpZD0iU0VSSUFMTlVNQkVSPUNVSVQgMjAzNzYyMjgyMjQsIENOPWNvbG9uaWFsIiBhdXRobWV0aG9kPSJjbXMiIHJlZ21ldGhvZD0iMjIiPgogICAgICAgICAgICA8cmVsYXRpb25zPgogICAgICAgICAgICAgICAgPHJlbGF0aW9uIGtleT0iMjAzNzYyMjgyMjQiIHJlbHR5cGU9IjQiLz4KICAgICAgICAgICAgPC9yZWxhdGlvbnM+CiAgICAgICAgPC9sb2dpbj4KICAgIDwvb3BlcmF0aW9uPgo8L3Nzbz4K", //afip.tokenAfip,
          sign: "orAFUYOf8eSKu7RGVFhpSzmaM2Lv8pqJAzMTuNugfba6fBqx3nGP1wzXs8YFMqt/l3H1pYVFss9q0fBJHADweO8rFmexO5Jnqagv/AcJZ6TG4U7Kszwzmhc2xT99e8HELze0CgFdxyiAhE6Vd57w+VYAwErlq4RBXzuoecF/5jA=", //afip.sign,
          uuid_user: uuid,
          uuid_branch: branch[0].uuid_branch,
          uuid_sale_point: selectedSalePoint.uuid,
          entry_date: selectedDate,
          coupons: table.map((coupon) => ({ uuid_coupon: coupon.uuid })),
        };
        await dispatch(addInvoices(invoiceData));
      }
    } catch (error) {
      console.error("Error during addRegisterPayment:", error);
    }
  };

  // Actualiza el estado cuando se selecciona un nuevo punto de venta
  const handleSalePointChange = (event) => {
    const selectedPoint = salePoints.find(
      (point) => point.uuid === event.target.value
    );
    setSelectedSalePoint(selectedPoint);
  };

  return (
    <Box
      sx={{
        mt: 3,
        justifyContent: "space-between",
        backgroundColor: "#f2f2f2",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        padding: "15px",
        boxShadow: "none",
        borderBottom: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
        overflowX: "auto", // permite el desplazamiento horizontal
        maxWidth: "90%", // Ajusta este valor a tu necesidad
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Cupon | Registrar | Registrar cobro
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={handleSaveCollection}
            sx={{ mr: 1 }}
          >
            Guardar Cobranza
          </Button>
          <Button variant="contained" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button variant="contained">Salir</Button>
        </Box>
      </Box>
      <Box sx={{ ml: 0, mt: 2, display: "flex", gap: 2 }}>
        <SelectFormControl sx={{ width: "15%" }}>
          <TextField
            label="Fecha"
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </SelectFormControl>
        <SelectFormControl sx={{ width: "15%" }}>
          <InputLabel id="plan-label">Plan</InputLabel>
          <Select
            labelId="plan-label"
            id="plan-select"
            value={filtros.plan}
            onChange={(e) => handleFilterChange("plan", e.target.value)}
            name="plan"
          >
            {plans.map((plan) => (
              <MenuItem key={plan.uuid} value={plan.uuid}>
                {plan.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ width: "15%" }}>
          <InputLabel id="group-label">Grupo</InputLabel>
          <Select
            labelId="group-label"
            id="group-select"
            value={filtros.group}
            onChange={(e) => handleFilterChange("group", e.target.value)}
            name="group"
          >
            {groups.map((group) => (
              <MenuItem key={group.uuid} value={group.uuid}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ width: "15%" }}>
          <InputLabel id="entidad-rec-label" shrink>
            Entidad Rec.
          </InputLabel>
          <Select
            labelId="entidad-rec-label"
            name="entidadRec"
            value={entidadSeleccionada}
            onChange={(e) => setEntidadSeleccionada(e.target.value)}
          >
            {entidadesRec.map((entidad) => (
              <MenuItem key={entidad.uuid} value={entidad.uuid}>
                {entidad.company}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ width: "15%" }}>
          <InputLabel id="punto-venta-label">P.vta</InputLabel>
          <Select
            labelId="punto-venta-label"
            name="puntoVenta"
            value={
              selectedSalePoint ? selectedSalePoint.uuid : salePoints[0].uuid
            }
            onChange={handleSalePointChange}
          >
            {salePoints?.map((punto, index) => (
              <MenuItem key={index} value={punto.uuid}>
                {punto.number}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        {selectedSalePoint?.invoice === true && (
          <FormControlLabel
            control={
              <Checkbox
                checked={makeElectronicInvoice}
                onChange={(e) => setMakeElectronicInvoice(e.target.checked)}
                name="noGenerarCAE"
                size="small"
              />
            }
            label="Generar CAE"
          />
        )}
      </Box>
      <Box
        sx={{ ml: 0, mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Box
          sx={{
            border: "1px solid #ccc",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "30%" }}>
            <Autocomplete
              options={contratos.map((option) => ({
                ...option,
                key: option.uuid,
              }))}
              getOptionLabel={(option) => {
                if (option.fee_type_name !== "Cuota") {
                  // Si el fee_type_name no es "Cuota"
                  return `${option.contract_number} - ${option.fee_type_name}`;
                } else {
                  // Si el fee_type_name es "Cuota"
                  const feeNumbers = [option.fee_number1];
                  if (option.fee_number2 !== null) {
                    feeNumbers.push(option.fee_number2);
                  }

                  const feeNumbersString = feeNumbers.join(" - ");

                  return `${option.contract_number} - Cuota: ${feeNumbersString}`;
                }
              }}
              style={{ width: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contrato:"
                  name="contract_number"
                  variant="outlined"
                  value={filtros.contract_number}
                  onChange={(e) =>
                    handleFilterChange("contract_number", e.target.value)
                  }
                />
              )}
              onChange={handleContratoChange}
              //   filterOptions={filterOptions} // Agrega esta línea
            />
          </Box>
          {contratoSeleccionado && (
            <Box sx={{ width: "60%" }}>
              <div>NOMBRE: {contratoSeleccionado.subscribers}</div>
              <div>
                Mes: {contratoSeleccionado.month} - {contratoSeleccionado.year}
              </div>
              <div>
                Fecha Venc.:{" "}
                {contratoSeleccionado.dueStage === "first_due"
                  ? contratoSeleccionado.first_due_date.slice(0, 10)
                  : contratoSeleccionado.dueStage === "second_due"
                  ? contratoSeleccionado.second_due_date.slice(0, 10)
                  : contratoSeleccionado.dueStage === "third_due"
                  ? contratoSeleccionado.third_due_date.slice(0, 10)
                  : "Generar Nuevo Venc."}
              </div>
              <div>
                Cuota/s: {contratoSeleccionado.fee_number1}{" "}
                {contratoSeleccionado.fee_number2 !== null &&
                contratoSeleccionado.fee_number2 !== undefined
                  ? ` - ${contratoSeleccionado.fee_number2}`
                  : ""}
              </div>
              <div>
                Importe:{" "}
                {contratoSeleccionado.dueStage === "first_due"
                  ? parseFloat(contratoSeleccionado.first_due_amount).toFixed(3)
                  : contratoSeleccionado.dueStage === "second_due"
                  ? parseFloat(contratoSeleccionado.second_due_amount).toFixed(
                      3
                    )
                  : contratoSeleccionado.dueStage === "third_due"
                  ? parseFloat(contratoSeleccionado.third_due_amount).toFixed(3)
                  : "Generar Nuevo Importe"}
              </div>
              <div>Coef.Puntaje: {contratoSeleccionado.score_coefficent}</div>
            </Box>
          )}
          <Box>
            <Button variant="contained" onClick={handleRegistrarClick}>
              Registrar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const RegisterPaymentForm = (props) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [totalCupones, setTotalCupones] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const addInvoiceStatus = useSelector(
    (state) => state.registerPayment.addInvoiceStatus
  );
  const error = useSelector((state) => state.registerPayment.error);
  const [showExpirationAlert, setShowExpirationAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  useEffect(() => {
    if (addInvoiceStatus === "succeeded") {
      setShowSuccessAlert(true);
    }
  }, [addInvoiceStatus]);

  useEffect(() => {
    setTotalCupones(tableData.length);

    const sumAmount = tableData.reduce(
      (acc, data) => acc + parseFloat(data.amount),
      0
    );
    setTotalAmount(sumAmount);
  }, [tableData]);

  const handleCloseSuccessAlert = () => {
    setShowSuccessAlert(false);
    dispatch(resetAddInvoiceStatus());
  };

  const handleRegistrarClick = (contrato) => {
    setTableData([...tableData, contrato]);
  };

  return (
    <>
      {addInvoiceStatus === "loading" && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mr: 0,
          ml: 5,
        }}
      >
        <RegisterPaymentHeader
          onRegistrarClick={handleRegistrarClick}
          table={tableData}
        />
        <Box
          sx={{
            justifyContent: "space-around",
            backgroundColor: "#f2f2f2",
            padding: "15px",
            border: "1px solid #ccc",
            p: 2,
            display: "flex",
            overflowX: "auto", // permite el desplazamiento horizontal
            maxWidth: "90%", // Ajusta este valor a tu necesidad
          }}
        >
          <Box>Cupones Ingresados: {totalCupones}</Box>
          <Box>Importe Total: $ {totalAmount.toFixed(2)}</Box>
        </Box>
        <GeneralTableBox
          sx={{
            overflowX: "auto", // permite el desplazamiento horizontal
            maxWidth: "90%", // Ajusta este valor a tu necesidad
            mt: 0,
            ml: 0,
            mr: 0,
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            boxShadow: "none",
            "*::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "*::-webkit-scrollbar-track": {
              background: "inherit",
              boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor: "gray",
              borderRadius: "20px",
            },
            "*::-webkit-scrollbar-corner": {
              background: "inherit",
            },
            "@media (min-width: 900px) and (max-width: 1536px)": {
              width: "100%",
            },
          }}
        >
          <GeneralTableContainer
            sx={{
              maxHeight: "45em",
              overflow: "auto",
            }}
          >
            <Table aria-label="plan table">
              <TableHead>
                <TableRow>
                  <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                    Contrato
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "3%" }}>
                    Suscriptor/s
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                    Cuota/s
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                    Mes
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "5%" }}>
                    Importe
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((data, key) => (
                  <TableRow key={key}>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      {data.contract_number}
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      {data.subscribers}
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      {data.fee_number1}{" "}
                      {data.fee_number2 !== null &&
                      data.fee_number2 !== undefined
                        ? ` - ${data.fee_number2}`
                        : ""}
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      {data.month}
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      {parseFloat(data.amount).toFixed(2)}
                    </RowTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GeneralTableContainer>
        </GeneralTableBox>
      </Box>
      <Dialog
        open={showSuccessAlert}
        onClose={handleCloseSuccessAlert}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="success-dialog-description">
            Su cobro se ha registrado con éxito.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessAlert} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegisterPaymentForm;
